import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Form from "./components/views/Form";
import FormSuccess from "./components/views/FormSuccess";
import Home from "./components/views/Home";
import Tele from "./components/views/Tele"; 
import MobileForm from "./components/views/MobileForm"; 
import MobileFormSuccess from "./components/views/MobileFormSuccess"; 



const Auth = () => {
  return (
    <Router>
      <Routes> 
        <Route path="/tele/:id" element={<Tele />} /> 
        <Route path="/form/:id" element={<Form />} /> 
        <Route path="/form-mobile/:id" element={<MobileForm />} /> 
        <Route path="/form/:id/success" element={<FormSuccess />} /> 
        <Route path="/form-mobile/:id/success" element={<MobileFormSuccess />} /> 
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
};

export default Auth;
