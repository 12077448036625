import React, { useEffect, useState } from "react";
import logo from './logo.svg'; 
import '../../index.css'


import {
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng";
import {useParams} from 'react-router-dom'
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
} from "agora-rtc-react";
import axios from "axios";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,faVideo,faVideoSlash,faVolumeMute,faVolumeXmark,faVolumeHigh } from '@fortawesome/free-solid-svg-icons'
import { baseUrl } from "../../config";

const config: ClientConfig = { 
  mode: "rtc", codec: "vp8",
};

const appId: string = "bbd13124346f4cd0b7209d1c22a7954d"; //ENTER APP ID HERE
 
 


 

function Tele() {
  const [inCall, setInCall] = useState(false);
  const [isSessionValid,setIsSessionValid] = useState(false);
  const [channelName, setChannelName] = useState("");  
  const [sessionData,setSessionData] = useState()
  const params = useParams();

  const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

 

useEffect(()=>{ 
  if(params?.id){
  setChannelName(params?.id)
  getRtcToken({channel:params?.id}, (res:any)=>{
   
    if(res.error){ 
        if(res?.message?.response?.data?.message)
        alert(res.message?.response?.data?.message)

        else
        alert("Session is not available at the moment. Dismiss this message and try refreshing this page or contact support via livechat at the bottom right of this page. We're online."); 
        return;
    } 
   
    setSessionData(res)
 
  })

}
},[])

const getRtcToken = async (data:any, callback:any) => {
  try { 
    const resp = await axios({
      method: "post",
      url: `${baseUrl}/appointments/teleweb`,
      data: data, 
    });

    return callback(resp.data);
  } catch (err) {
    // Handle Error Here
    console.error(err);
    return callback({ error: true, message: err });
  }
};

const VideoCall = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>;
  channelName: string;
}) => {
  const { setInCall, channelName } = props;
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [start, setStart] = useState<boolean>(false);
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();



  useEffect(() => {
    // function to initialise the SDK
    let init = async (config: any) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        console.log("subscribe success");
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      });
  
      client.on("user-unpublished", (user, type) => {
        console.log("unpublished", user, type);
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });
  
      client.on("user-left", (user) => {
        console.log("leaving", user);
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });
  
      await client.join(appId, channelName, config?.token, config?.uid);
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);
  
    };
  
    if (ready && tracks) {
      console.log("init ready");

   

      init(sessionData)
    
    }
  
  }, [channelName, client, ready, tracks]);

  return (
    <div className="App">
    
      {start && tracks && <Videos users={users} tracks={tracks} />}
      <div className="controls-wrapper">
      {ready && tracks && (
        <Controls tracks={tracks} setStart={setStart} setInCall={setInCall} />
      )}
      </div>
    </div>
  );

}

const Videos = (props: {
  users: IAgoraRTCRemoteUser[];
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack];
}) => {
  const { users, tracks } = props;

  return (
    <div>
      <div id="videos">
        <AgoraVideoPlayer className='vid' videoTrack={tracks[1]} />
        {users.length > 0 &&
          users.map((user) => {
            if (user.videoTrack) {
              return (
                <AgoraVideoPlayer className='vid' videoTrack={user.videoTrack} key={user.uid} />
              );
            } else return null;
          })}
      </div>
    </div>
  );
};

 const Controls = (props: {tracks:any, setStart:any, setInCall:any}) => {
  const client = useClient();
  const { tracks, setStart, setInCall } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  
  const mute = async (type: "audio" | "video") => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };
  
  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
  };

  return (
    <div className="controls">
      <p className={trackState.audio ? "on" : ""}
        onClick={() => mute("audio")}>
        {trackState.audio ? <ControlComponent title="Mute" icon={faVolumeHigh} /> : <ControlComponent title="UnMute" icon={faVolumeMute} />}
      </p>
      <p className={trackState.video ? "on" : ""}
        onClick={() => mute("video")}>
        {trackState.video ? <ControlComponent title="Stop Video" icon={faVideo} /> : <ControlComponent title="Show Video" icon={faVideoSlash} />}
      </p>
      {<p className="leave-button" onClick={() => leaveChannel()}>Leave</p>}
    </div>
  );
};


const ControlComponent = (props:any)=>{
 const {title,icon} = props;
    return (<div className="control-container">
    <FontAwesomeIcon icon={icon} />
            <span>{title}</span>
           
    </div>)
}

const ChannelForm = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>;
  setChannelName: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { setInCall, setChannelName } = props;

  return (
    <form className="join">
      {appId === '' && <p style={{color: 'red'}}>Please enter your App ID and refresh the page</p>}
      { <div><h1 className="heading">You have been invited to a Telehealth session</h1></div>}
      {<p>By clicking "Join Session", you agree to our <a href="https://gaphealth.ai/terms-and-conditions/">Terms of use</a>, <a href="https://gaphealth.ai/privacy-policy/">Privacy Policy</a> and <a href="https://gaphealth.ai/consent-to-telemedicine/">Consent to Telehealth</a> </p>}
      {/* <input type="text"
        placeholder="Enter Channel Name"
        onChange={(e) => setChannelName(e.target.value)}
      /> */}
      <button onClick={(e) => {
        e.preventDefault();
        console.log(channelName)
        setInCall(true);
      }}>
        Join Session
      </button>
    </form>
  );
};




  return (
    <>
    <div className="container-fluid"> 
   
      <div className="nav">
        <div>   <img style={{ width: "12rem" }} src="https://gaphealth.ai/wp-content/uploads/2022/06/GapHealth.png" /></div>
        <div><div><a href="https://gaphealth.ai/contact/" target={"_blank"}>Support</a></div></div>
      </div>
     
      {(inCall) ? (
        <VideoCall setInCall={setInCall} channelName={channelName} />
      ) : (
        <ChannelForm setInCall={setInCall} setChannelName={setChannelName} />
      )}

<div className="footer"><p>&copy;2022 <a href="https://gaphealth.ai" target={"_blank"}>GAPhealth Technologies Inc.</a> All rights reserved.</p></div>
    </div>
   
    </>
  );
  
  
}








export default Tele;
