import { useEffect, useState } from "react";
import '../../index.css';
import '../../formbuilder.scss';

import {Link, useParams} from 'react-router-dom'

import { ReactFormBuilder,ReactFormGenerator } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import { baseUrl } from "../../config";
import axios from "axios";
import Swal from "sweetalert2";

const FormSuccess = () =>{
    const [formData,setFormData] = useState <any>(null)
    const params = useParams();
 
 
 
    useEffect(()=>{
       console.log(params)
   
    },[])
    return (
        <div className="kt_custom_form_view">
        <div className="container-fluid">
       
          <div className="nav">
            <div>   <img style={{ width: "12rem" }} src="https://gaphealth.ai/wp-content/uploads/2022/06/GapHealth.png" /></div>
            <div><div><a href="https://gaphealth.ai/contact/" target={"_blank"}>Support</a></div></div>
          </div>
       
 
 { <div className="text-center mt-5 pt-5"><h1 className="heading">Thank you very much for your submission</h1>
 <Link to={`/form/${params?.id}`} replace={true}>Submit another response</Link>
 </div>}
 
 
<footer className="page-footer font-small blue pt-4">

 
  
 
  <div className="footer-copyright text-center py-3"> 
  <p>&copy;2022 <a href="https://gaphealth.ai" target={"_blank"}>GAPhealth Technologies Inc.</a> All rights reserved.</p>
  </div>
 

</footer>
 
        </div>
       
        </div>
      );
}

export default FormSuccess;