import { useEffect, useState } from "react";
import '../../index.css';
import '../../formbuilder.scss';

import {Link, useParams} from 'react-router-dom'

import { ReactFormBuilder,ReactFormGenerator } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import { baseUrl } from "../../config";
import axios from "axios";
import Swal from "sweetalert2";

const MobileFormSuccess = () =>{
    const [formData,setFormData] = useState <any>(null)
    const params = useParams();
 
 
 
    useEffect(()=>{
       console.log(params)
   
    },[])
    return (
        <div className="kt_custom_form_view">
        <div className="container-fluid"> 
 { <div className="text-center mt-5 pt-5"><h4 className="heading">Thank you very much for your submission!</h4>
 
 </div>} 
 
<footer className="page-footer font-small blue pt-4">

 
  
 
 
 

</footer>
 
        </div>
       
        </div>
      );
}

export default MobileFormSuccess;