import { useEffect, useState } from "react";
import '../../index.css';
import '../../formbuilder.scss';

import {Link, useNavigate, useParams} from 'react-router-dom'

import { ReactFormBuilder,ReactFormGenerator } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import { baseUrl } from "../../config";
import axios from "axios";
import Swal from "sweetalert2";

const Form = () =>{
    const [formData,setFormData] = useState <any>(null)
    const params = useParams();
const navigate = useNavigate();

const [isFormError,setIsFormError] = useState(false)

  const getForm = async ()=>{
    try { 
        const resp = await axios({
          method: "GET",
          url: `${baseUrl}/custom-forms/${params?.id}/teleweb`, 
         
        });
       
        if(resp.data){
            if(resp?.data?.status === "error")
           setIsFormError(true);
            console.log(resp.data)
            setFormData(resp.data)
        }

      } catch (err) {
        // Handle Error Here
        console.error(err);
        return 
        // callback({ error: true, message: err });
      }
  }


  const submitForm = async (data :any) =>{
    try { 
        Swal.showLoading();
      
        const resp = await axios({
          method: "POST",
          url: `${baseUrl}/custom-forms/${params?.id}/answers`, 
          data: {id:params?.id,payload:data}
        
        });

        Swal.close();
       
        if(resp.data){
      
           
            navigate(`/form/${params?.id}/success`) 
         
        }

      } catch (err) {
        // Handle Error Here
        Swal.close();
        console.error(err);
        Swal.fire({
            title: "Error!",
            text: "An error occurred while submitting your information. Please try again.",
            icon: "error",
            confirmButtonText: "Okay",
            confirmButtonColor: "#00bfa6",
          }).then((res) => {});
        return 
      
      }
  }

    useEffect(()=>{
       console.log(params)
       getForm();
    },[])
    return (
        <div className="kt_custom_form_view">
        <div className="container-fluid">
        
       
          <div className="nav">
            <div>   <img style={{ width: "12rem" }} src="https://gaphealth.ai/wp-content/uploads/2022/06/GapHealth.png" /></div>
            <div><div><a href="https://gaphealth.ai/contact/" target={"_blank"}>Support</a></div></div>
          </div>
       
      {isFormError && 
      <div className="text-center mt-5 pt-5 form-error"><h1 className="heading">Form is unavailable or is not currently receiving submissions</h1>
    
      <button onClick={(e) => {
        e.preventDefault();
        window.location.reload()
      }}>
       Refresh page
      </button>
      </div>
      } 
 {formData?.payload?.length > 0 &&<div className="container mt-5 pt-5 mb-5">
 <ReactFormGenerator
    form_action="/path/to/form/submit"
    form_method="POST"
    task_id={12}   
    data={formData?.payload}  
    onSubmit={submitForm}
  />
 </div>}

 
        
    
     
 
<footer className="page-footer font-small blue pt-5 mt-5">

 
 
 
  <div className="footer-copyright text-center py-3"> 
  <p>&copy;2022 <a href="https://gaphealth.ai" target={"_blank"}>GAPhealth Technologies Inc.</a> All rights reserved.</p>
  </div>
 

</footer>
 
        </div>
       
        </div>
      );
}

export default Form;