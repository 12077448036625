import { useEffect, useState } from "react";
import '../../index.css';
import '../../formbuilder.scss';

import { useNavigate, useParams,useLocation} from 'react-router-dom'

import { ReactFormGenerator } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import { baseUrl } from "../../config";
import axios from "axios";
import Swal from "sweetalert2";

const MobileForm = () =>{
    const [formData,setFormData] = useState <any>(null)
    const params = useParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search)
const navigate = useNavigate();

 
const [isFormError,setIsFormError] = useState(false)

  const getForm = async ()=>{
   let userToken = urlParams.get("token");
   let userHeaders = {};
    if(userToken){
    userHeaders=   {
        Authorization: `Bearer ${urlParams.get("token")}`,
      }}

    try { 
        const resp = await axios({
          method: "GET",
          url: `${baseUrl}/custom-forms/${params?.id}/teleweb`, 
          headers:userHeaders
        });
       
        if(resp.data){
            if(resp?.data?.status === "error")
           setIsFormError(true);
           
            setFormData(resp.data) 
        }

      } catch (err) {
        // Handle Error Here
      
        console.error(err);
        return 
      
      }
  }


  const submitForm = async (data :any) =>{
    let userToken = urlParams.get("token");
    let userHeaders = {};
   
     if(userToken){
     userHeaders=   {
         Authorization: `Bearer ${urlParams.get("token")}`,
       }}
    try { 
        Swal.showLoading();
      
        const resp = await axios({
          method: "POST",
          url: `${baseUrl}/custom-forms/${params?.id}/answers`, 
          data: {id:params?.id,payload:data},
          headers:userHeaders
        });

        Swal.close();
       
        if(resp.data){ 
            navigate(`/form-mobile/${params?.id}/success`)  
        }

      } catch (err) {
        // Handle Error Here
        Swal.close();
        console.error(err);
        Swal.fire({
            title: "Error!",
            text: "An error occurred while submitting your information. Please try again.",
            icon: "error",
            confirmButtonText: "Okay",
            confirmButtonColor: "#00bfa6",
          }).then((res) => {});
        return 
 
      }
  }

    useEffect(()=>{
       console.log(params)
       
       getForm();
    },[])
    return (
        <div className="kt_custom_form_view">
        <div className="container-fluid">
         
    
       
      {isFormError && 
      <div className="text-center mt-5 pt-5 form-error"><h1 className="heading">Form is unavailable or is not currently receiving submissions</h1>
    
      <button onClick={(e) => {
        e.preventDefault();
        window.location.reload()
      }}>
       Refresh page
      </button>
      </div>
      } 
 {formData?.payload?.length > 0 &&<div className="container mt-2 pt-2 mb-5">
 
 <ReactFormGenerator
    form_action="/path/to/form/submit"
    form_method="POST"
    task_id={12}   
    data={formData?.payload}  
    onSubmit={submitForm}
  />
 </div>}

 
        
 
 
        </div>
       
        </div>
      );
}

export default MobileForm;