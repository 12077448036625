import { useEffect } from "react";

const Home = () =>{

    useEffect(()=>{
        window.location.replace("https://gaphealth.ai")
    },[])
    return (<></>)
}

export default Home;